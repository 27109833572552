import NavMenu from "../../../../components/public/others/Navbar";
import NavMenuCM from "../../../../components/public/others/NavbarCM";
import Footer from "../../../../components/public/others/Footer";
import { TipoPortal } from "../../../../services/variables";
import MenuSalaEmpreendedor from "../../../../components/public/transparency/salaEmpreendedor/MenuEmpreendedor";
import SubTitle from "./../../../../components/public/others/title/SubTitle";
import "./homeSala.css";

export default function homeSalaEmpreendedor(props) {
  const route = props.route;

  return (
    <>
      {/* tipo 4 == Câmara  tipo 3 == prefeitura*/}
      {TipoPortal == 3 ? (
        <>
          <NavMenu />
        </>
      ) : (
        <>
          <NavMenuCM />
        </>
      )}
      <div className="section-title rotes_pages">
        <h6>Início {route}</h6>
      </div>
      <div className="section-title-portarias">
        <h4>Sala Empreendedor</h4>
      </div>
      <MenuSalaEmpreendedor filter={"Início"} />
      <SubTitle
        subTitle={"Conheça a Sala do Empreendedor Digital"}
        border={true}
      />
      <div className="section-title">
        <p>
          A Sala do Empreendedor Digital foi criada com o objetivo de facilitar
          o acesso aos serviços voltados aos empreendedores ou futuros
          empreendedores. Preparamos esse ambiente digital onde você poderá
          fazer facilmente a abertura da sua empresa, alteração cadastral,
          solicitação de alvará, emissão de nota fiscal, baixa de empresa e
          outros serviços em um só lugar e com a facilidade do mundo digital.
        </p>
      </div>
      <div className="buttons-enpreendedor">
        <h2>Acesse nossos serviços, clique nos botões abaixo</h2>
      </div>
      <div className="buttons-enpreendedor">
        <a
          href="https://mei.receita.economia.gov.br/inscricao/login"
          target="_balnk"
          rel="noreferrer"
        >
          Quero me formalizar
        </a>
        <a
          href="https://mei.receita.economia.gov.br/certificado/login"
          target="_balnk"
          rel="noreferrer"
        >
          Emissão de comprovante CCMEI
        </a>
        <a
          href="https://mei.receita.economia.gov.br/alteracao/login"
          target="_balnk"
          rel="noreferrer"
        >
          Atualização cadastral
        </a>
        <a
          href="https://www8.receita.fazenda.gov.br/SimplesNacional/Aplicacoes/ATSPO/pgmei.app/Identificacao"
          target="_balnk"
          rel="noreferrer"
        >
          Boleto de pagamento
        </a>
        <a
          href="https://www8.receita.fazenda.gov.br/SimplesNacional/Servicos/Grupo.aspx?grp=19"
          target="_balnk"
          rel="noreferrer"
        >
          Parcelamento de débitos
        </a>
        <a
          href="https://www8.receita.fazenda.gov.br/SimplesNacional/Aplicacoes/ATSPO/dasnsimei.app/Identificacao"
          target="_balnk"
          rel="noreferrer"
        >
          Declaração anual de faturamento
        </a>
        <a
          href="https://mei.receita.economia.gov.br/baixa/login"
          target="_balnk"
          rel="noreferrer"
        >
          Baixa da empresa - MEI
        </a>
        <a
          href="https://solucoes.receita.fazenda.gov.br/servicos/cnpjreva/cnpjreva_solicitacao.asp"
          target="_balnk"
          rel="noreferrer"
        >
          Emissão de CNPJ
        </a>
        <a
          href="https://sistemas1.sefaz.ma.gov.br/sintegra/jsp/consultaSintegra/consultaSintegraFiltro.jsf"
          target="_balnk"
          rel="noreferrer"
        >
          Inscrição Estadual / SINTEGRA - MA
        </a>
        <a
          href="https://sistemas1.sefaz.ma.gov.br/nfae/jsp/login/login.jsf"
          target="_balnk"
          rel="noreferrer"
        >
          NFAe - Comércio
        </a>
        <a
          href="https://www.nfse.gov.br/EmissorNacional/Login?ReturnUrl=%2fEmissorNacional"
          target="_balnk"
          rel="noreferrer"
        >
          NFAe - MEI
        </a>
        <a
          href="https://www.regularize.pgfn.gov.br/login"
          rel="noreferrer"
          target="_balnk"
        >
          Regularize
        </a>
        <a
          href="https://ma.loja.sebrae.com.br/"
          target="_balnk"
          rel="noreferrer"
        >
          Cursos Online - SEBRAE
        </a>
      </div>
      <br />
      <div className="buttons-enpreendedor">
        <h2>Redes sociais da Sala do Empreendedor</h2>
      </div>
      <div className="buttons-enpreendedor">
        <a
          className="link"
          target="_blank"
          href="https://www.instagram.com/p/C74s1TyvnIb/?igsh=MTk4OWJ3cXJqcHIzZw=="
        >
          <img
            src="/icons/instagram.svg"
            alt="logo Instagram"
            className="svg-social"
          />
          <p className="text-social">Instagram</p>
        </a>
        {/* <a
          className="link"
          target="_blank"
          href="https://www.facebook.com/people/Sala-Do-Empreendedor-Turil%C3%A2ndia-Ma/100083860777924/"
        >
          <img
            src="/icons/facebook.svg"
            alt="logo Facebook"
            className="svg-social"
          />
          <p className="text-social">Facebook</p>
        </a> */}
        <a
          className="link"
          target="_blank"
          href="https://l.instagram.com/?u=https%3A%2F%2Fwa.me%2F5599985003273&e=AT0iFo7Xag5W3ff5Ql3s3OaJawCQTeVgKC8eUSd6kBeYPvg1iDwTS1nxgg3pvB6ZPwzNZlwJ4t7aXXYw__eKxCRlj7KJpzLEpRV2DA"
        >
          <img
            src="/icons/whatsapp.svg"
            alt="logo whatsapp"
            className="svg-social"
          />
          <p className="text-social">WhatsApp</p>
        </a>
        <a
          className="link"
          target="_blank"
          href="mailto:faleconosco@buritibravo.ma.gov.br"
        >
          <img
            src="/icons/envelopeDark.svg"
            alt="logo whatsapp"
            className="svg-social"
          />
          <p className="text-social">Email</p>
        </a>
      </div>
      <br />
      <Footer />
    </>
  );
}
